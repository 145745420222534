<template>
  <router-view></router-view>
  <PageLoad v-if="status" />
</template>

<script>
import { useAuthStore, useLoadingStore, useTheme } from "@/store";
import { mapState, mapActions } from "pinia";
import PageLoad from "@/pages/chat/PageLoad.vue";

export default {
  name: "App",
  data() {
    return {
      connection: null,
    };
  },
  async created() {
    if (this.$route.path == "/" && localStorage.getItem("token")) {
      await this.getUser();
    }
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        console.log("Bildirim izni verildi!");
      }
    });
  },
  components: {
    PageLoad,
  },
  computed: {
    ...mapState(useLoadingStore, ["status"]),
  },
  methods: {
    ...mapActions(useAuthStore, ["getUser"]),
    ...mapActions(useTheme, ["getThemeStatus"]),
  },
  mounted() {
    this.getThemeStatus();
  },
};
</script>
<style>
.chat-leftsidebar {
  box-shadow: 6px -3px 19px -10px #0000001c;
}
.chat-list li a {
  border-top: none !important;
  border-radius: none !important;
}
.active-user{
  border: 3px solid #6adb07e3;
}
</style>
