import { defineStore } from "pinia";

export const useTypingStore = defineStore("connectionStore",{
    state: () => ({
        typing: false
      }),

    actions:{
        setTyping(status){
          this.typing = status
        }
    }  
})

